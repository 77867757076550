.main-container {
  max-width: 100% !important;
}
body {
  /* background-color: #edf2f7; */
  background-color: #f1f3f6;
}
.p-header-wrapper {
  /* background-color: #fff; */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  /* border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 12px 0 !important; */
  /* border-bottom: 1px solid rgb(199, 198, 198); */
}

.orange-btn {
  font-size: 14px !important;
  padding: 10px 12px !important;
  border: transparent !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4) !important;
  background: #e55c35 !important;
  color: white !important;
  border-radius: 4px !important;
}

.orange-btn:hover {
  background-color: #ff7d4d !important;
}

.orange-btn:active {
  transform: translate(0em, 0.2em) !important;
}

.filter-button {
  /* display: none ; */
  position: absolute;
  right: 0;
}

/* For tablets and larger devices */
@media (min-width: 600px) {
  .filter-section {
    display: none;
  }
  .filter-button {
    display: none !important;
  }
}

/* For laptops and desktops */
@media (min-width: 1024px) {
  .filter-button {
    display: none !important;
  }
  .filter-section {
    display: block;
  }
}

/* For smaller mobile devices */
@media (max-width: 599px) {
  .filter-section {
    display: none;
  }
}
