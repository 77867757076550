/* .product-card {
  border: 1px solid #33376f;
  position: relative;
  border-radius: 5px !important;
  overflow: hidden; 
  transition: 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: #33376f;
} */

.product-card {
  background: #edf2f7 !important;
  padding: 8px;
  border-radius: 5px !important;
  border: 1px solid gainsboro;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06); */
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;

  /* margin: 0px auto; */
}

.product-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.product-card .MuiCardMedia-root {
  height: 300px; /* Ensure image height consistency */
  object-fit: fill;
  /* object-fit: cover; */
}

.sold-out,
.ready-to-ship {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e55c35; /* Red for sold out */
  color: white;
  padding: 8px 12px;
  border-radius: 3px;
  font-size: 0.875rem; /* Adjust font size for labels */
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.ready-to-ship {
  background-color: #d9534f; /* Green for ready to ship */
}

.product-card .MuiCardContent-root {
  text-align: center;
}

.MuiPagination-root {
  margin-top: 20px;
  margin-bottom: 20px;
}

.MuiPagination-ul {
  display: flex;
  justify-content: flex-end;
}
.no-records {
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  font-size: 1.2rem; /* Larger font size for visibility */
  color: #d9534f; /* Gray color for the message */
  font-weight: bold; /* Make the text bold for emphasis */
}
