@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@400;700&display=swap");

.roboto-serif {
  font-family: "Roboto Serif", serif;
}

/* expertice section */
.card:hover .card-body {
  height: calc(100% - 50px);
}

/* testimonials section */
.testimonials {
  background-image: linear-gradient(
      rgba(22, 28, 45, 0.9),
      rgba(22, 28, 45, 0.95)
    ),
    url("../../src/assets/testimonials-bg2.png");
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(25px);
  border: 2px solid white;
}

// footer
ul {
  text-decoration: none;
  list-style-type: none;
  text-decoration: none;
  padding: 0;
}

ul li {
  color: rgb(213, 210, 210);
  padding: 7px 0px;
}

ul li:hover,
.footer-icon:hover {
  cursor: pointer;
  color: #ff7d4d;
}

// / KEYFRAMES
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.zoom-in {
  animation: zoomIn 0.5s linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.slideInFromLeft {
  animation: slideInFromLeft 0.5s linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.fade-in {
  animation: fadeIn 0.5s linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.fade-up {
  animation: fade-up 0.5s;
}

.fade-down {
  animation: fade-down 0.5s;
}
